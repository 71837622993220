<template>
  <div class="page">
    <h1>受発注フォーマット</h1>
    <v-card class="pa-5" flat outlined>
      <!-- Expansion -->
      <v-expansion-panels flat v-model="searchPanel" id="search_panel">
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>

          <!-- Body -->
          <v-expansion-panel-content>
            <v-form ref="registForm">
              <v-autocomplete
                v-model="selectTokuiFull"
                :items="dispTokuiList"
                outlined
                label="得意先"
                :item-text="item => `${item.option1}：${item.name}`"
                item-value="code"
                chips
                @change="changeSelectTokuiFull(selectTokuiFull)"
                :rules="[selected]"
              >
              </v-autocomplete>
              <v-autocomplete
                v-model="selectFormat"
                :items="formatList"
                outlined
                label="フォーマット"
                :item-text="item => `${item.formatId}：${item.indexName}`"
                item-value="formatId"
                chips
                @change="changeSelectFormat(selectFormat)"
              >
              </v-autocomplete>
              <v-text-field
                v-model="inputFormatName"
                prepend-inner-icon="mdi-text"
                label="名称"
                outlined
              />
              <v-autocomplete
                v-model="selectKaiuke"
                :items="kaiukeList"
                outlined
                label="買受人"
                item-text="name"
                item-value="code"
                chips
                v-if="tokuiBunrui"
                clearable
                :rules="[selected]"
              />
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-card-actions class="ma-2">
        <!-- 得意先選択ラジオボタン（得意先でグループ選択時に利用） -->
        <div id="tokui_radio_group" v-show="dispTableFlag">
          <template>
            <v-radio-group
              v-model="dispTokuiIndex"
              row
              v-show="dispTokuiBtnFlag"
            >
              <v-radio
                v-for="(btndata, index) in btnTokuiList"
                color="indigo"
                :key="index"
                :label="btndata.nm1 + btndata.nm2"
                :value="index"
                @change="changeTokuiRadio(index)"
              ></v-radio>
            </v-radio-group>
          </template>
        </div>

        <v-spacer></v-spacer>
        <Btn
          icon="mdi-magnify"
          color="primary"
          @click="get('')"
          style="margin-left: 50px"
          >取得</Btn
        >
        <Btn
          icon="mdi-delete"
          color="error"
          @click="dlt('')"
          style="margin-left: 50px"
          >削除</Btn
        >
      </v-card-actions>
    </v-card>
    <v-card class="" v-show="dispTableFlag">
      <v-card-actions>
        <Btn
          icon="plus"
          color="info"
          @click="rowAdd('')"
          style="margin-left: 10px"
          >行追加
        </Btn>
        <Btn
          icon="mdi-lead-pencil"
          color="success"
          @click="upd('')"
          style="margin-left: auto"
          >更新</Btn
        >
      </v-card-actions>
    </v-card>
    <v-card class="" v-show="dispTableFlag">
      <p
        style="font-size: 20px;
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 2px;
          margin-left: 15px;"
      >
        {{ dispTokuiName }}
      </p>
    </v-card>
    <v-card class="" id="meisai_card" v-show="dispTableFlag">
      <div
        ref="table"
        class="v-data-table_format v-data-table--has-bottom theme--light"
      >
        <div id="jyutyu_format_table" class="v-data-table__wrapper_format">
          <table class="format_table_sticky" style="border-color: grey;">
            <thead class="v-data-table-header">
              <tr>
                <th v-for="(cal, i) in tableHead" :key="i">
                  {{ cal }}
                </th>
              </tr>
            </thead>
            <tbody ref="tbody">
              <template v-for="(rowData, j) in tableDispData">
                <tr :key="j">
                  <!-- 産地Cd -->
                  <td class="td-santiCd">
                    <input
                      type="text"
                      v-model="rowData.santiCd"
                      class="table_col_input_text"
                      @keydown.enter.exact="getsantiNm(j, 1)"
                      @keydown.right.exact="getsantiNm(j, 1)"
                      @keydown.left.exact="getsantiNm(j, 99)"
                    />
                  </td>
                  <!-- 産地 -->
                  <td class="td-santi">
                    <input
                      type="text"
                      v-model="rowData.santiNm"
                      class="table_col_input_text"
                      @keydown.enter.exact="sendFocus(j, 2)"
                      @keydown.right.exact="sendFocus(j, 2)"
                      @keydown.left.exact="sendFocus(j, 0)"
                    />
                  </td>
                  <!-- 商品Cd -->
                  <td class="td-shohinCd">
                    <input
                      type="text"
                      v-model="rowData.shohinCd"
                      class="table_col_input_text"
                      @keydown.enter.exact="getshohinNm(j, 3)"
                      @keydown.right.exact="getshohinNm(j, 3)"
                      @keydown.left.exact="getshohinNm(j, 1)"
                    />
                  </td>
                  <!-- 品名 -->
                  <td class="td-shohin">
                    <input
                      type="text"
                      v-model="rowData.shohinNm"
                      class="table_col_input_text"
                      @keydown.enter.exact="sendFocus(j, 4)"
                      @keydown.right.exact="sendFocus(j, 4)"
                      @keydown.left.exact="sendFocus(j, 2)"
                    />
                  </td>
                  <!-- 規格 -->
                  <td class="td-kikaku">
                    <input
                      type="text"
                      v-model="rowData.kikakuNm"
                      class="table_col_input_text"
                      @keydown.enter.exact="sendFocus(j, 5)"
                      @keydown.right.exact="sendFocus(j, 5)"
                      @keydown.left.exact="sendFocus(j, 3)"
                    />
                  </td>
                  <!-- 入数/発注数 -->
                  <td class="td-number">
                    <input
                      type="number"
                      v-model="rowData.irisuu"
                      class="table_col_input_number"
                      min="0"
                      step="1"
                      @keydown.enter.exact="sendFocus(j, 6)"
                      @keydown.right.exact="sendFocus(j, 6)"
                      @keydown.left.exact="sendFocus(j, 4)"
                    />
                  </td>
                  <td class="td-number">
                    <input
                      type="number"
                      v-model="rowData.hattyusuu"
                      class="table_col_input_number"
                      min="0"
                      step="1"
                      @keydown.enter.exact="sendFocus(j, 7)"
                      @keydown.right.exact="sendFocus(j, 7)"
                      @keydown.left.exact="sendFocus(j, 5)"
                    />
                  </td>
                  <!-- 単位 -->
                  <td class="td-tanni">
                    <input
                      type="text"
                      v-model="rowData.taniNm"
                      class="table_col_input_text"
                      @keydown.enter.exact="sendFocus(j, 8)"
                      @keydown.right.exact="sendFocus(j, 8)"
                      @keydown.left.exact="sendFocus(j, 6)"
                    />
                  </td>
                  <!-- 実数/単価/備考 -->
                  <td class="td-number">
                    <input
                      type="number"
                      v-model="rowData.jissuu"
                      class="table_col_input_number"
                      min="0"
                      step="1"
                      @keydown.enter.exact="sendFocus(j, 9)"
                      @keydown.right.exact="sendFocus(j, 9)"
                      @keydown.left.exact="sendFocus(j, 7)"
                    />
                  </td>
                  <td class="td-number">
                    <input
                      type="number"
                      v-model="rowData.tanka"
                      class="table_col_input_number"
                      min="0"
                      step="1"
                      @keydown.enter.exact="sendFocus(j, 10)"
                      @keydown.right.exact="sendFocus(j, 10)"
                      @keydown.left.exact="sendFocus(j, 8)"
                    />
                  </td>
                  <td class="td-text">
                    <input
                      type="text"
                      v-model="rowData.bikou"
                      class="table_col_input_text"
                      @keydown.enter.exact="sendFocus(j, 11)"
                      @keydown.right.exact="sendFocus(j, 11)"
                      @keydown.left.exact="sendFocus(j, 9)"
                    />
                  </td>
                  <!-- 担当Cd -->
                  <td class="td-tantoCd">
                    <input
                      type="text"
                      v-model="rowData.tantoCd"
                      class="table_col_input_text"
                      @keydown.enter.exact="gettantoNm(j, 12)"
                      @keydown.right.exact="gettantoNm(j, 12)"
                      @keydown.left.exact="gettantoNm(j, 10)"
                    />
                  </td>
                  <!-- 担当 -->
                  <td class="td-tanto">
                    <input
                      type="text"
                      v-model="rowData.tantoNm"
                      class="table_col_input_text"
                      @keydown.enter.exact="sendFocus(j, 13)"
                      @keydown.right.exact="sendFocus(j, 13)"
                      @keydown.left.exact="sendFocus(j, 11)"
                    />
                  </td>
                  <td class="table-button" style="text-align: center;">
                    <template class="table_col_input_action">
                      <v-icon @click="rowIns(j)" color="success">
                        mdi-plus
                      </v-icon>
                      <v-icon @click="rowDel(j)" color="error">
                        mdi-delete
                      </v-icon>
                    </template>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";
import Api from "@/mixins/api";
import Vue from "vue";

const reg = new RegExp(/^[0-9]+$/);
const baseRow = {
  santiId: "",
  santiCd: "",
  santiNm: "",
  shohinIds: "",
  shohinId: "0",
  edano: "0",
  shohinCd: "",
  shohinNm: "",
  kikakuId: "",
  kikakuNm: "",
  irisuu: "",
  hattyusuu: "",
  taniId: "",
  taniNm: "",
  jissuu: "",
  tanka: "",
  bikou: "",
  tantoId: null,
  tantoCd: "",
  tantoNm: "",
  presantiCd: "",
  preshohinCd: "",
  pretantoCd: ""
};

export default {
  name: "JyutyuFormat",
  mixins: [Common, ShowDialogs, Api],
  components: {},
  data: () => ({
    // セレクト（ダミー）
    dispTokuiList: [],
    btnTokuiList: [],
    formatList: [],
    kaiukeList: [],
    shohinList: [],
    santiList: [],
    tantoList: [],
    selectTokuiFull: "",
    selectTokuiCode: "",
    selectTokuiType: "",
    selectFormat: "",
    selectKaiuke: "",
    inputFormatName: "",
    ckbn: "",
    dispTokuiIndex: 0,
    dispTokuiIndexOld: 0,
    dispTokuiId: "",
    dispTokuiBtnFlag: false,
    dispTableFlag: false,
    dispTokuiName: "",
    // ダミー
    inputedShohinValue: "",
    // テーブル
    tableHead: [
      "産地Cd",
      "産地",
      "商品Cd",
      "品名",
      "規格",
      "入数",
      "発注数",
      "単位",
      "実数",
      "単価",
      "備考",
      "担当Cd",
      "担当者"
    ],
    tableDispData: [],
    allTableData: [],
    searchPanel: 0,
    filterPanel: 0,
    // 入力規則
    selected: value => !!value || "選択してください", // 選択必須の制約
    required: value => !!value || "入力してください", // 入力必須の制約
    numeric: value => reg.test(value) || "数字を入力してください"
  }),
  computed: {
    tokuiBunrui() {
      const tagCode = this.selectTokuiFull;
      const tokuiList = this.dispTokuiList;
      const tagTokui = tokuiList.find(e => e.code === tagCode);
      if (tagTokui) {
        return tagTokui.option2 === "1";
      }
      return false;
    }
  },
  methods: {
    // ==============================
    // 検索
    // ==============================
    async get() {
      console.log("get", "開始");
      this.$loading();
      try {
        // allTableData
        //  └ index(得意先ボタンの選択Indexと一致)
        //    └ 得意先別フォーマット明細リスト
        const customerId = this.selectTokuiFull;
        const formatId = this.selectFormat;
        const formatName = this.inputFormatName;
        console.log("選択された得意先", customerId);
        console.log("選択されたフォーマット", formatId);
        console.log("選択された名称", formatName);
        if (!formatName) {
          this.$error("フォーマット名称を入力してください");
          return;
        }
        if (this.$refs.registForm.validate()) {
          this.dispTableFlag = true;
          // すべてのバリデーションが通過したときのみ if文の中に入る
          // 選択中の得意先IDを保持
          this.dispTokuiId = this.btnTokuiList[this.dispTokuiIndex].id;
          this.allTableData = [];
          if (formatId) {
            // フォーマットが選択されていた場合
            for (const tokuiUnitData of this.btnTokuiList) {
              const data = await this.getFormatDel(tokuiUnitData);
              this.allTableData.push(data);
              if (this.dispTokuiId === tokuiUnitData.id) {
                this.tableDispData = data;
              }
            }
            console.log("submit", "success");
          } else {
            // 上記以外の場合、一覧、全得意先データを初期化
            this.tableDispData = [];
            this.allTableData = [];
          }

          const tok = this.dispTokuiList.filter(
            e => e.code === this.selectTokuiFull
          );
          console.log("dispTokuiList", tok);
          if (tok.length > 0) {
            this.dispTokuiName = tok[0].name + " : " + this.inputFormatName;
          }
        } else {
          console.log("submit", "failed");
          this.$error("取得失敗!");
        }
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async deleteConfirm() {
      return await this.$deleteConfirm(
        "現在選択中のフォーマットを削除します。\nよろしいですか？"
      );
    },
    async rowDelConfirm() {
      return await this.$deleteConfirm(
        "現在選択中の行を削除します。\nよろしいですか？"
      );
    },
    // ==============================
    // 削除
    // ==============================
    async dlt() {
      console.log("dlt", "開始");
      this.$loading();
      try {
        const customerId = this.selectTokuiFull;
        const formatId = this.selectFormat;
        const formatName = this.inputFormatName;
        console.log("選択された得意先", customerId);
        console.log("選択されたフォーマット", formatId);
        console.log("選択された名称", formatName);
        if (!formatId) {
          this.$error("フォーマット選択してください");
          return;
        }
        if ((await this.deleteConfirm()) != true) {
          return;
        }
        const param = {
          formatId: this.selectFormat
        };
        const encode = encodeURI(JSON.stringify(param));

        // 削除実施
        await this.$del("/jyutyu", "query=" + encode);
        // 完了後、フォーマットプルダウン再取得、フォーマット選択値初期化
        this.selectFormat = "";
        await this.getDispFormatList();
        this.$info("更新しました。", "受発注フォーマット");
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    // ==============================
    // 更新
    // ==============================
    async upd() {
      console.log("upd", "開始");
      const customerId = this.selectTokuiFull;
      const formatId = this.selectFormat;
      const formatName = this.inputFormatName;
      console.log("選択された得意先", customerId);
      console.log("選択されたフォーマット", formatId);
      console.log("選択された名称", formatName);
      if (!formatName) {
        //this.$error("フォーマット名称を入力してください");
        //return;
      }
      if (!this.$refs.registForm.validate()) return;

      //コンボボックスはフォーカスが外れないと値を更新しない
      if (this.$refs.shohinCombobox) {
        this.$refs.shohinCombobox.forEach(e => e.blur());
      }
      this.$nextTick(async () => {
        try {
          this.$loading();
          // 表示中の一覧データを全得意先データに格納
          // this.allTableData[this.dispTokuiIndex] = Object.assign({}, this.tableDispData);
          for (let i = 0; i < this.btnTokuiList.length; i++) {
            this.allTableData[i] = Vue.util.extend(this.tableDispData);
          }
          console.log(this.allTableData);
          // テーブルデータチェック
          const errMsg = this.checkTableDispData();
          if (errMsg) {
            this.$error(errMsg);
            return;
          }
          // 更新前にテーブル情報を整理
          const updMeisaiData = [];
          for (let i = 0; i < this.btnTokuiList.length; i++) {
            const editTable = this.allTableData[i];
            const tokuiId = this.btnTokuiList[i].id;
            let gyo = 1;
            for (const rowIndex in editTable) {
              const row = Vue.util.extend({}, editTable[rowIndex]);
              // ・フォーマットが選択されている場合は、フォーマットIDを設定
              // ・得意先ID、行番号を設定
              if (this.selectFormat) row.formatId = this.selectFormat;
              row.tokuiId = tokuiId;
              row.gyo = gyo;
              if (!row.irisuu) row.irisuu = null;
              row.santiId = this.strToInt(row.santiId);
              row.kikakuId = this.strToInt(row.kikakuId);
              row.tantoId = this.strToInt(row.tantoId);
              updMeisaiData.push(row);
              gyo++;
            }
          }
          console.log(this.updMeisaiData);
          // 更新実施
          await this.bulkUpdate(updMeisaiData);
          // 完了後、フォーマットプルダウン再取得
          await this.getDispFormatList();
          this.$info("更新しました。", "受発注フォーマット");
        } catch (e) {
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      });
    },
    strToInt(str) {
      if (!str) return null;
      if (isNaN(str)) return null;
      return parseInt(str, 10);
    },
    // ==============================
    // 行操作
    // ==============================
    async rowIns(index) {
      console.log("rowIns", "開始");
      const newRowData = Vue.util.extend({}, baseRow);
      this.tableDispData = [
        ...this.tableDispData.slice(0, index),
        ...[newRowData],
        ...this.tableDispData.slice(index)
      ];
      this.tableDispData.splice();
    },
    async rowDel(index) {
      console.log("rowDel", "開始");
      if ((await this.rowDelConfirm()) != true) return;
      this.tableDispData.splice(index, 1);
    },
    async rowAdd() {
      console.log("rowAdd", "開始");
      const newRowData = Vue.util.extend({}, baseRow);
      this.tableDispData.push(newRowData);
      this.$nextTick(function() {
        const el = this.$refs.table;
        if (el) {
          el.scrollTo(0, el.scrollHeight);
        }
      });
    },
    // ==============================
    // 各種チェック
    // ==============================
    checkTableDispData() {
      let errMsg = "";
      let isNoData = true;
      // 得意先毎にはチェックせず、表示中の一覧に対してチェックを行う。
      for (const rowIndex in this.tableDispData) {
        const checkTableDispIndex = +rowIndex + 1;
        const row = this.tableDispData[rowIndex];
        isNoData = false;
        if (
          !row.santiNm &&
          !row.shohinNm &&
          !row.kikakuNm &&
          !row.irisuu &&
          !row.hattyusuu &&
          !row.taniNm &&
          !row.jissuu &&
          !row.tanka &&
          !row.bikou &&
          !row.tantoNm
        ) {
          errMsg += "・" + checkTableDispIndex + "行目:入力がありません\n";
          continue;
        }
      }
      if (isNoData) {
        return "明細データが存在しません";
      }

      return errMsg;
    },
    // ==============================
    // 得意先別に受発注フォーマット明細を取得
    // ==============================
    async getFormatDel(tokuiData) {
      console.log("getDispFormatDelList");
      const param = {
        formatId: this.selectFormat,
        tokuiId: tokuiData.id
      };
      const encode = encodeURI(JSON.stringify(param));
      const formatData = await this.$get(
        this.Paths.jyutyuFormat + "/list/formatdel",
        "query=" + encode
      );
      const tableData = [];
      for (const rowIndex in formatData) {
        const row = formatData[rowIndex];
        tableData.push({
          santiCd: "",
          santiNm: row.santiNm,
          shohinCd: "",
          shohinNm: row.shohinNm,
          kikakuNm: row.kikakuNm,
          irisuu: row.irisuu,
          hattyusuu: row.hattyusuu,
          taniNm: row.taniNm,
          jissuu: row.jissuu,
          tanka: row.tanka,
          bikou: row.bikou,
          tantoCd: "",
          tantoNm: row.tantoNm,
          presantiCd: "",
          preshohinCd: "",
          pretantoCd: ""
        });
      }
      console.log(tableData);
      return tableData;
    },
    // ==============================
    // 受発注フォーマットヘッダ、明細一括更新
    // ==============================
    async bulkUpdate(updMeisaiData) {
      console.log("bulkUpdate");
      // パラメータ設定
      const paramHed = {
        userId: this.$store.state.userincode,
        tokuiId: this.selectTokuiType === "G" ? "0" : this.selectTokuiCode,
        indexId: "0",
        indexName: this.inputFormatName,
        isValid: "1",
        kaiukeId: this.selectKaiuke
      };
      if (this.selectTokuiType === "G")
        paramHed.tokuiGroup = this.selectTokuiCode;
      if (this.selectFormat) paramHed.formatId = this.selectFormat;
      const param = {
        hedderData: paramHed,
        meisaiDataList: updMeisaiData
      };
      // フォーマットIDが選択されている場合は、設定（更新へ）
      if (this.selectFormat) param.formatId = this.selectFormat;
      try {
        await this.$put("/jyutyu", param);
        return;
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    // ==============================
    // プルダウン更新
    // ==============================
    async getDispTokuiList() {
      console.log("getDispTokuiList");
      const tokuiData = await this.$get(this.Paths.jyutyuPulldown + "/tokui");
      console.log(tokuiData);
      return tokuiData;
    },
    async getSantiList() {
      console.log("getSantiList");
      const santiData = await this.$get(this.Paths.jyutyuPulldown + "/santi");
      console.log("santiData", santiData);
      return santiData;
    },
    async getShohinList() {
      console.log("getShohinList");
      const shohinData = await this.$get(
        this.Paths.jyutyuPulldown + "/shohinnm"
      );
      console.log(shohinData);
      return shohinData;
    },
    async getTantoList() {
      console.log("getTantoList");
      const tantoData = await this.$get(this.Paths.jyutyuPulldown + "/tanto");
      console.log("tantoData", tantoData);
      return tantoData;
    },
    async getDispFormatList() {
      console.log("getDispFormatList");
      const param = {
        userId: this.$store.state.userincode,
        isValid: 1
      };
      if (this.selectTokuiType === "G") {
        param.tokuiGroup = this.selectTokuiCode;
      } else {
        param.tokuiId = this.selectTokuiCode;
      }
      try {
        const encode = encodeURI(JSON.stringify(param));
        const formatData = await this.$get(
          this.Paths.jyutyuFormat + "/list/formathed",
          "query=" + encode
        );
        this.formatList = formatData;
        console.log(formatData);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async getKaiukeList() {
      console.log("getKaiukeList");
      const kaiukeData = await this.$get(this.Paths.jyutyuPulldown + "/kaiuke");
      console.log(kaiukeData);
      return kaiukeData;
    },
    // ==============================
    // 得意先変更に係る画面表示変更
    // ==============================
    // 得意先が変更された場合
    changeSelectTokuiFull(newVal) {
      // 一覧情報、他を初期化
      this.tableDispData = [];
      this.allTableData = [];
      this.dispTableFlag = false;
      this.dispTokuiIndex = 0;
      this.dispTokuiIndexOld = 0;
      this.inputFormatName = "";
      this.selectKaiuke = "";
      this.dispTokuiName = "";
      // 未選択の場合は初期化
      if (!newVal) {
        // 未選択（null）の場合は、以下をクリア
        this.selectTokuiCode = "";
        this.selectTokuiType = "";
        this.ckbn = "";
        this.formatList = [];
        this.btnTokuiList = [];
        return;
      }
      const tokuiAry = newVal.split("-");
      this.selectTokuiCode = tokuiAry[1];
      this.selectTokuiType = tokuiAry[0]; // T or G(グループ)
      this.setDispDataByTokuiChange();
    },
    async setDispDataByTokuiChange() {
      // 得意先リストを取得し、関連得意先ラジオボタンを生成
      await this.getBtnTokuiList();
      // フォーマットプルダウン更新
      await this.getDispFormatList();
    },
    async getBtnTokuiList() {
      console.log("getBtnTokuiList");
      const param = {
        tokuiType: this.selectTokuiType,
        targetId: this.selectTokuiCode
      };
      console.log("getBtnTokuiList", param);
      const encode = encodeURI(JSON.stringify(param));
      const btnTokuiData = await this.$get(
        this.Paths.jyutyuFormat + "/list/tokui",
        "query=" + encode
      );
      this.$nextTick(function() {
        // クリア後、ボタンの再描画
        this.btnTokuiList = [];
        this.btnTokuiList = btnTokuiData;
        this.btnTokuiList.splice();
        // 初期情報の設定
        this.ckbn = this.btnTokuiList[0].ckbn; // 会社区分
        this.dispTokuiId = this.btnTokuiList[0].id; // 表示中の得意先ID
        this.dispTokuiBtnFlag = this.btnTokuiList.length > 1; // 関連得意先の数が1以上の場合に表示
      });
      return;
    },
    // ==============================
    // フォーマットプルダウン変更
    // ==============================
    changeSelectFormat(formatId) {
      const formatList = this.formatList;
      const tagFormat = formatList.find(e => e.formatId === formatId);
      if (tagFormat) {
        this.inputFormatName = tagFormat.indexName;
        if (tagFormat.kaiukeId) this.selectKaiuke = String(tagFormat.kaiukeId);
      }
    },
    // ==============================
    // 得意先ラジオボタン変更
    // ==============================
    changeTokuiRadio(index) {
      // Change結果の中止が出来ない為、この時点でチェックはしない。
      // レコードUpdate時にまとめてチェックする。

      // 各得意先毎に個別にフォーマットを持つのではなく
      // 同内容を保持するようになったため、対応。
      // 受発注入力側では個別で保持する。
      // this.allTableData[this.dispTokuiIndexOld] = this.tableDispData;
      // this.tableDispData = this.allTableData[index];
      for (let i = 0; i < this.btnTokuiList.length; i++) {
        this.allTableData[i] = Vue.util.extend(this.tableDispData);
      }
      this.tableDispData = this.allTableData[index];
      /*this.dispTokuiName =
        "▼" + this.btnTokuiList[index].nm1 + this.btnTokuiList[index].nm2;*/

      this.tableDispData.splice(); // 再描画
      this.dispTokuiIndexOld = index;
    },
    sendFocus(tr, td) {
      console.log("tr", tr);
      console.log("td", td);
      const ele = this.findSabledElement(tr, td);
      setTimeout(() => ele?.focus(), 150);
    },
    getsantiNm(tr, td) {
      console.log("tr", tr);
      console.log("td", td);
      const cdele = this.findSabledElement(tr, 0);
      const nmele = this.findSabledElement(tr, 1);
      console.log("nmele", nmele);
      const value = cdele?.value != "" ? ("000" + cdele?.value).slice(-3) : "";
      if (value != "") {
        console.log("tableDispData", this.tableDispData[tr]);
        const row = Vue.util.extend({}, this.tableDispData[tr]);
        if (row.presantiCd != value) {
          console.log("value", value);
          const santi = this.santiList.filter(e => e.option1 === value);
          console.log("santi", santi);
          this.tableDispData[tr].presantiCd = value;
          this.tableDispData[tr].santiNm = santi[0].name;
        }
      }

      const ele = this.findSabledElement(tr, td);
      setTimeout(() => ele?.focus(), 150);
    },
    getshohinNm(tr, td) {
      console.log("tr", tr);
      console.log("td", td);
      const cdele = this.findSabledElement(tr, 2);
      const nmele = this.findSabledElement(tr, 3);
      console.log("nmele", nmele);
      const value =
        cdele?.value != "" ? ("000000" + cdele?.value).slice(-6) : "";
      if (value != "") {
        const row = Vue.util.extend({}, this.tableDispData[tr]);
        if (row.preshohinCd != value) {
          console.log("value", value);
          const shohin = this.shohinList.filter(e => e.code === value);
          console.log(shohin);
          this.tableDispData[tr].preshohinCd = value;
          this.tableDispData[tr].shohinNm = shohin[0].name;
        }
      }

      const ele = this.findSabledElement(tr, td);
      setTimeout(() => ele?.focus(), 150);
    },
    gettantoNm(tr, td) {
      console.log("tr", tr);
      console.log("td", td);
      const cdele = this.findSabledElement(tr, 11);
      const nmele = this.findSabledElement(tr, 12);
      console.log("nmele", nmele);
      const value = cdele?.value;
      if (value != "") {
        const row = Vue.util.extend({}, this.tableDispData[tr]);
        console.log("row.pretantoCd", row.pretantoCd);
        if (row.pretantoCd != value) {
          console.log("value", value);
          const tanto = this.tantoList.filter(e => e.code === value);
          console.log("tanto", tanto);
          this.tableDispData[tr].pretantoCd = value;
          this.tableDispData[tr].tantoNm = tanto[0].name;
        }
      }

      const ele = this.findSabledElement(tr, td);
      setTimeout(() => ele?.focus(), 150);
    },
    findSabledElement(trIndex, tdIndex) {
      const tbody = this.$refs.tbody;
      const trList = [...tbody.rows];
      const tr = trList.find(e => e.rowIndex === trIndex + 1);
      if (tr) {
        const tdList = [...tr.cells];
        const td = tdList.find(e => e.cellIndex === tdIndex);
        if (td) {
          const input = td.querySelector("input");
          if (input && !input.disabled) {
            return input;
          } else {
            return this.findSabledElement(trIndex, tdIndex + 1);
          }
        } else {
          console.log("tdIndex", tdIndex);
          if (tdIndex == 99) {
            return this.findSabledElement(trIndex - 1, 9);
          } else {
            return this.findSabledElement(trIndex + 1, 0);
          }
        }
      }
      return null;
    }
  },
  async created() {
    console.log("created");
    // 得意先プルダウン情報取得
    this.dispTokuiList = await this.getDispTokuiList();
    //買受人情報取得
    this.kaiukeList = await this.getKaiukeList();
    // 商品マスタの一覧を取得
    this.shohinList = await this.getShohinList();
    // 産地マスタの一覧を取得
    this.santiList = await this.getSantiList();
    // 担当差hマスタの一覧を取得
    this.tantoList = await this.getTantoList();
  }
};
</script>
<style lang="scss" scoped>
/* 検索条件 */
#search_panel ::v-deep .v-select__slot {
  max-height: 50px;
}
#search_panel ::v-deep .v-expansion-panel-content__wrap {
  padding-bottom: 0;
}
/* ラジオボタン */
#tokui_radio_group ::v-deep .v-input--radio-group {
  margin-top: 0;
}
#tokui_radio_group ::v-deep .v-radio {
  margin: 0 5px 5px 0;
  padding: 8px 15px;
  background-color: #cce6ff;
  border-radius: 20px;
  .v-label {
    font-size: 20px;
  }
}
#tokui_radio_group ::v-deep .v-radio.v-item--active {
  background-color: #0091c5;
  .v-label {
    font-size: 20px;
    color: white;
  }
}

#tokui_radio_group ::v-deep .v-input--selection-controls__input {
  display: none;
}
/* フィルタ条件 */
#filter_panel ::v-deep button.v-expansion-panel-header {
  padding: 0 24px;
  max-height: 10px !important;
}
/* 明細部分 */
#meisai_card ::v-deep .v-input__slot {
  padding-right: 0 !important;
  min-height: 50px;
}
#meisai_card ::v-deep tbody tr td {
  vertical-align: middle;
  padding: 2px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.38);
}
#jyutyu_format_table ::v-deep .format_table_sticky thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #ffed00;
  border-top: #ffffff;
}
.table_col_input_number {
  border-spacing: 1px;
  border-collapse: collapse;
  border-style: solid;
  border-width: 1px;
  text-align: right;
  height: 50px;
  width: 100%;
}
.table_col_input_text {
  border-spacing: 1px;
  border-collapse: collapse;
  border-style: solid;
  border-width: 1px;
  height: 50px;
  width: 100%;
  padding: 0 3px;
}
.td-santiCd,
.td-santi,
.td-kikaku,
.td-tanni,
.td-tantoCd,
.td-tanto,
.td-shohinCd,
.td-text {
  width: 6%;
}

.td-shohin {
  width: 18%;
}
.td-number {
  width: 7%;
}
.v-data-table_format {
  overflow-y: scroll;
  height: 75vh;
}

.theme--light.v-data-table_format
  > .v-data-table__wrapper_format
  > table
  > thead
  > tr:last-child
  > th,
.theme--light.v-data-table_format
  > .v-data-table__wrapper_format
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table_format
  > .v-data-table__wrapper_format
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.v-data-table_format > .v-data-table__wrapper_format > table > tbody > tr > td,
.v-data-table_format > .v-data-table__wrapper_format > table > tbody > tr > th,
.v-data-table_format > .v-data-table__wrapper_format > table > thead > tr > td,
.v-data-table_format > .v-data-table__wrapper_format > table > thead > tr > th,
.v-data-table_format > .v-data-table__wrapper_format > table > tfoot > tr > td,
.v-data-table_format > .v-data-table__wrapper_format > table > tfoot > tr > th {
  padding-left: 4px;
  padding-right: 4px;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  color: rgba(0, 0, 0, 0.6);
}
/* タブレット縦 */
@media screen and (max-width: 1024px) {
  #meisai_card {
    width: 1000px;
    overflow-x: scroll;
  }
  .table_col_input_santi,
  .table_col_input_kikaku,
  .table_col_input_tanni,
  .table_col_input_tanto,
  .table_col_input_text {
    min-width: 100px;
  }
  .table_col_input_shohin {
    min-width: 200px;
  }
  .table_col_input_number {
    min-width: 50px;
  }
}
/* スマホ横 */
@media screen and (max-width: 896px) {
  #meisai_card {
    width: 850px;
    overflow-x: scroll;
  }
  .table_col_input_santi,
  .table_col_input_kikaku,
  .table_col_input_tanni,
  .table_col_input_tanto,
  .table_col_input_text {
    min-width: 100px;
  }
  .table_col_input_shohin {
    min-width: 200px;
  }
  .table_col_input_number {
    min-width: 50px;
  }
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
  #meisai_card {
    width: 450px;
    overflow-x: scroll;
  }
  .table_col_input_santi,
  .table_col_input_kikaku,
  .table_col_input_tanni,
  .table_col_input_tanto,
  .table_col_input_text {
    min-width: 100px;
  }
  .table_col_input_shohin {
    min-width: 200px;
  }
  .table_col_input_number {
    min-width: 50px;
  }
}
/* 印刷用画面 */
@page {
  size: A4 landscope;
  margin: 4% 7% 4% 5%;
}
</style>
<style lang="scss">
@media print {
  .page > :not(#meisai_card) {
    display: none !important;
  }
  .table-button {
    display: none !important;
  }
  nav {
    display: none !important;
  }
  header {
    display: none !important;
  }
  main {
    padding: 0 !important;
  }
  .container {
    max-width: 100% !important;
    padding: 0 !important;
  }
  .v-data-table_format {
    height: auto !important;
    width: auto !important;
    overflow: hidden !important;
  }
}
</style>
